._1Lxpd {
  width: 100%;
  z-index: 10;
}
._30Ipg {
  background: white;
  border: 1px solid gray;
  width: 100%;
}
@media screen and (min-width: 768px) {
  ._30Ipg {
    width: 70%;
  }
}
.css-13cymwt-control {
  border: 1px solid grey !important;
  border-radius: 0.375rem !important;
}
