.active-purple-btn {
  background-color: #5d78ff !important;
  color: white;
  /* padding: 0.5rem 1rem; */
  font-size: 1rem;
  font-weight: 300;
}
.purple-btn {
  background-color: white;
  color: #595d6e;
  border: 1px solid #e2e5ec;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 300;
}
.active-purple-btn:hover {
  background-color: #5d78ff;
  color: white;
  /* padding: 0.5rem 1rem; */
}
.purple-btn:hover {
  filter: brightness(0.9);
  /* padding: 0.5rem 1rem; */
}
.report-table {
  font-weight: 500;
  border-bottom-width: 1px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  font-size: 13px;
}
