.rmsc multi-select{
    width: 100;
}
.width{
    width: 100%;
}


/* Add a placeholder to the dropdown menu */
.pac-container::before {
    content: "Select a location..."; /* Your placeholder text */
    color: #999; /* Placeholder text color */
    font-style: italic; /* Optional: Italicize the text */
    /* You can adjust the positioning and styles as needed */
    position: absolute;
    top: 8px;
    left: 12px;
    pointer-events: none; /* This ensures the placeholder text is not selectable */
  }
  