.phone-input {
  border: 1px solid #e3e9f0;
  padding: 7px;
  border-radius: 5px;
}
.phone-input input {
  outline: none;
}
.css-1577qb8{
  align-items: start !important;
}
.css-1ydjfm6 {
  margin-top: 5px !important;
}