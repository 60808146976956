
@media screen and (min-width: 300px) {
    .chakra-input {
    width: "300px";
    }
  }
  
  /* Styles for screens smaller than or equal to 1200px */
  @media screen and (max-width: 1199px) {
    .chakra-input {
      width: "217px";
      /* Add other styles for smaller screens as needed */
    }
  }
  

@media (max-width: 1024px) {
    .chakra-select {
        width: 230px;
    }
}