.react-datepicker__input-container input {
  background: white;
  color: #565656;
  border-radius: 5px;
  text-align: center;
  font-weight: 500 !important;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  border: 1px solid #e3e3e3;
}
.react-datepicker-ignore-onclickoutside {
  background: white;
  color: "black";
  border-radius: 5px;
}

.react-datepicker__day--selected :hover {
  background:#5867DD ;
  text-shadow: none;
}
.react-datepicker__day--keyboard-selected {
  background: #5867DD;
  text-shadow: none;
}
.react-datepicker__day--keyboard-selected:hover {
  background: #5867DD;
  text-shadow: none;
}
.react-datepicker__header {
  background: #5867DD;
}
.react-datepicker__current-month {
  color: white;
  text-shadow: none;
}
.react-datepicker__day-names {
}
.react-datepicker__day-name {
  color: white;
  text-shadow: none;
}
.react-datepicker {
  border: 1px solid #5867DD;
}

.react-datepicker__navigation--previous {
  outline: none !important;
  border: none;
}
.react-datepicker__navigation--next {
  outline: none !important;
  border: none;
}
.react-datepicker__year-read-view {
  margin-top: 10px;
}
.react-datepicker__year-read-view--selected-year {
  color: white;
  text-shadow: none;
  font-size: 12px;
  font-weight: 600;
}
.react-datepicker__year-dropdown {
  color: white;
  background: #5867DD;
  border: 1px solid #5867DD;
  text-shadow: none;
  font-size: 12px;
}
.react-datepicker__year-option {
}
.react-datepicker__year-option:hover {
  color: #fff;
  background: #5867DD;
}
