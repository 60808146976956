.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgb(143 143 143 / 78%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  backdrop-filter: blur(2px);
  margin-top: 0 !important;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  overflow: hidden;
  max-height: 600px;
  height: 600px;
  width: 100%;
  max-width: 700px;
}

.modal-body {
  overflow: auto;
  height: calc(100% - 40px);
}
