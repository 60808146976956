.css-9cz1ob {
  border: 1px solid grey !important;
  border-radius: 0.375rem !important;
}
.css-1l438rw {
  border: 1px solid grey !important;
  border-radius: 0.375rem !important;
}
.css-vtg3j9 {
  border: 1px solid grey !important;
}
