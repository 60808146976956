.css-17ps2nv {
  border-radius: 10px !important;
}
.css-deopwh {
  border-radius: 10px !important;
}

.css-9fgtzh {
  background: #5867DD;
  color: white;
  border-radius: 10px 10px 0px 0px;
}
