::-webkit-scrollbar {
  display: block;
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-right: none;
  border-left: none;
  border-radius: 10px;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
body {
  color: #48465b !important;
}
.css-7a0nsa {
  color: #48465b !important;
}
/* Add this CSS to your stylesheet or component style */

/* Styling for the pagination container */
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 20px 0px 0px 0px;
}
.container > li {
  padding: 5px;
}
.page-item > a {
  padding: 5px !important;
  border-radius: 6px !important;
}
.page {
  padding: 1px !important;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 4px;
  background: #fff;
  border: 1px solid #e9e9e9;
}
.page > a {
  padding: 13px 10px !important;
}
.active-venu-button {
  color: white;
  padding: 10px !important;
  background-color: #5867dd !important;
  font-weight: bold;
  width: fit-content;
  height: 40px;
  align-items: center;
  text-align: center;
}

.css-b1kc00 {
  white-space: normal !important;
}
.css-1y3ap1b {
  white-space: normal !important;
  word-break: break-word !important;
}
.css-1ml31uj {
  white-space: normal !important;
}
.css-t897f {
  white-space: normal !important;
}
.css-11fzxdh {
  border: 1px solid grey !important;
  border-radius: 0.375rem !important;
}
.react-datepicker__input-container input {
  border: 1px solid grey !important;
}
.css-bh2zww {
  border: 1px solid grey !important;
}
