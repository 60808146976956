.container {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 10px;
    padding-top: 10px;
  }
  .page {
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 4px;
  }
  .disabled {
    cursor: not-allowed;
    color: grey;
  }
  .active-venu-button{
    color: white;
    background-color: #5867DD;
    font-weight: bold;
    width: 50px;
    height: 40px;
    align-items: center;
  text-align: center;
  }
  .previous {
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
  .break {
    padding: 10px;
  }
  .next {
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
  .text-menu{
    color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  }